export class navigation {
    constructor() {
        this.navigation();
        this.redirects();
    }

    navigation(): void {
        const allToggleButtons = document.querySelectorAll('.navigation__toggle');

        allToggleButtons.forEach((toggleButton) => {
            const targetId = toggleButton.getAttribute('data-target');
            const target = document.getElementById(targetId!);
            const isFirstLevel = toggleButton.classList.contains('navigation__toggle-first-level');

            toggleButton.addEventListener('click', (event) => {
                const currentState = toggleButton.getAttribute('aria-expanded');
                const setState = currentState === 'false';
                toggleFunction(setState, target, toggleButton as HTMLElement, isFirstLevel, event);
            });
        });

        const toggleFunction = (
            state: boolean,
            target: HTMLElement | null,
            toggleBtn: HTMLElement | null,
            isFirstLevel: boolean,
            event: Event
        ) => {
            if (isFirstLevel) {
                //hide other targets
                const openToggleBtns = document.querySelectorAll('.navigation__toggle[aria-expanded="true"]');
                openToggleBtns.forEach((btn) => {
                    const openTargetId = btn.getAttribute('data-target');
                    const openTarget = document.getElementById(openTargetId!);
                    setState(false, openTarget, btn as HTMLElement, isFirstLevel, event);
                });

                const _elToHide = target!
                    .closest('.collapse__first-level')!
                    .querySelectorAll(
                        '.navigation__list-wrapper .navigation__list .navigation__link-first-level, .navigation__list-wrapper .list-header'
                    );

                hideElements(true, _elToHide as NodeListOf<HTMLElement>);

                if (state) {
                    document.getElementById('top')?.classList.add('nav-open');
                } else {
                    document.getElementById('top')?.classList.remove('nav-open');
                }
            }

            setState(state, target, toggleBtn as HTMLElement, isFirstLevel, event);
        };

        const setState = (
            state: boolean,
            target: HTMLElement | null,
            toggleBtn: HTMLElement | null,
            isFirstLevel: boolean,
            event: Event | null
        ) => {
            const previousBtn = target!.previousElementSibling as HTMLElement;
            const isDesktopView = window.matchMedia('(min-width: 1200px)');

            target!.querySelector('.navigation__toggle')!.setAttribute('aria-expanded', `${state}`);
            toggleBtn!.setAttribute('aria-expanded', `${state}`);
            previousBtn.setAttribute('aria-expanded', `${state}`);
            document.querySelector('.navigation-wrapper')!.scrollTo({ top: 0 });

            if (!isFirstLevel) {
                const _levelAbove = target!.closest('.collapse-inner');
                const _elToHide = target!
                    .closest('.collapse__first-level')!
                    .querySelectorAll(
                        '.navigation__list-wrapper .navigation__list .navigation__link-first-level, .navigation__list-wrapper > .list-header'
                    );
                const targetHeight = target!.firstElementChild!.clientHeight;

                if (isDesktopView.matches) {
                    if (state) {
                        const _levelAboveHeight = _levelAbove!.clientHeight;
                        if (_levelAboveHeight < targetHeight) {
                            (_levelAbove as HTMLElement).style.height = targetHeight + 32 + 'px';
                        }
                    } else {
                        (_levelAbove as HTMLElement).style.removeProperty('height');

                        const _levelAboveHeight = _levelAbove!.clientHeight;
                        (_levelAbove as HTMLElement).style.height = _levelAboveHeight + 'px';
                    }
                }

                hideElements(!state, _elToHide as NodeListOf<HTMLElement>);
            } else {
                if (isDesktopView.matches) {
                    const _collapseInner = target?.querySelector('.collapse-inner');
                    if (_collapseInner) {
                        (_collapseInner as HTMLElement)!.style.height = _collapseInner!.clientHeight + 'px';
                    }
                }
            }

            if (state) {
                target!.classList.add('show');
                if (!(event as MouseEvent)?.detail) {
                    setTimeout(() => {
                        (target!.querySelector('.navigation__link') as HTMLElement).focus();
                    }, 250);
                }
            } else {
                target!.classList.remove('show');
                if (!(event as MouseEvent)?.detail) {
                    previousBtn.focus();
                }
            }
        };

        const hideElements = (show: boolean, elements: NodeListOf<HTMLElement>) => {
            elements.forEach((_el) => {
                !show ? _el.classList.add('invisible') : _el.classList.remove('invisible');
            });
        };

        // toggle body class if mobile nav is open
        document.querySelectorAll('.navigation-toggle').forEach((toggle) => {
            toggle.addEventListener('click', () => {
                if (toggle.getAttribute('aria-expanded') === 'true') {
                    document.getElementById('top')?.classList.add('nav-open-mobile');
                } else {
                    document.getElementById('top')?.classList.remove('nav-open-mobile');
                }
            });
        });

        // close all open navigation items when mobile nav is being closed
        document.getElementById('navigation')?.addEventListener('hidden.bs.collapse', () => {
            document.getElementById('top')?.classList.remove('nav-open');
            const openItems = document.querySelectorAll('.navigation__toggle[aria-expanded="true"]');
            openItems.forEach((item) => {
                const target = document.getElementById(item.getAttribute('data-target')!);
                if (target !== null) setState(false, target, item as HTMLElement, true, null);
            });
        });

        // reset navigation when window is being resized
        window.addEventListener('resize', () => {
            if (!document.activeElement!.classList.contains('solr-search__form-input')) {
                const searchToggle = document.querySelectorAll<HTMLButtonElement>('.solr-search__toggle');
                searchToggle.forEach((toggle) => {
                    if (toggle.nextElementSibling!.classList.contains('open')) {
                        toggle.click();
                    }
                });
            }

            const openItems = document.querySelectorAll('.navigation__toggle[aria-expanded="true"]');
            openItems.forEach((item) => {
                const target = document.getElementById(item.getAttribute('data-target')!);
                if (target !== null) setState(false, target, item as HTMLElement, true, null);
            });

            document.getElementById('top')?.classList.remove('nav-open');
            document
                .querySelector<HTMLButtonElement>('button[data-bs-target="#navigation"][aria-expanded="true"]')
                ?.click();
        });

        // close navigation on ESC or on click outside of nav
        document.addEventListener('keydown', (event) => {
            const openNav = document.querySelector('.collapse-wrapper.show');
            if (event.key === 'Escape' && openNav) {
                (openNav.previousElementSibling as HTMLButtonElement).click();
            }
        });

        document.addEventListener('click', (event) => {
            if (event.target instanceof Element) {
                if (!document.getElementById('header')?.contains(event.target)) {
                    const openNav = document.querySelector('.collapse-wrapper.show');
                    (openNav?.previousElementSibling as HTMLButtonElement)?.click();
                }
            }
        });
    }

    redirects(): void {
        // redirect to value of select option
        const linkSelect: NodeListOf<HTMLSelectElement> = document.querySelectorAll('.link-select');
        linkSelect.forEach((linkSelect) => {
            linkSelect.addEventListener('change', () => {
                window.location.href = linkSelect.value;
            });
        });
    }
}
